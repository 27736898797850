export default {
    SET_DASHBOARD_QUERY: 'SET_DASHBOARD_QUERY',
    UNSET_DASHBOARD_QUERY: 'UNSET_DASHBOARD_QUERY',
    LOAD_DASHBOARD: 'LOAD_DASHBOARD',
    LOAD_DASHBOARD_RESP: 'LOAD_DASHBOARD_RESP',
    CHANGE_OPTION: 'CHANGE_OPTION',
    CHANGE_OPTION_BATCH: 'CHANGE_OPTION_BATCH',
    SAVE_OPTIONS: 'SAVE_OPTIONS',
    LOAD_OPTIONS: 'LOAD_OPTIONS',
    LOAD_PINNED: 'LOAD_PINNED',
    SAVE_PINNED: 'SAVE_PINNED',
    HANDLE_PIN: 'HANDLE_PIN',
    RESET_OPTIONS: 'RESET_OPTIONS',
    LOAD_RELEASES: 'LOAD_RELEASES',
    LOAD_RELEASES_RESP: 'LOAD_RELEASES_RESP',
    LOAD_SCHEDULE: 'LOAD_SCHEDULE',
    LOAD_SCHEDULE_RESP: 'LOAD_SCHEDULE_RESP',
    LOAD_CACHING_INFO: 'LOAD_CACHING_INFO',
    LOAD_CACHING_INFO_RESP: 'LOAD_CACHING_INFO_RESP',
    THROW_ERROR: 'THROW_ERROR',
    SET_DEP_GRAPH: 'SET_DEP_GRAPH',
    SET_SERVER_ERROR: 'SET_SERVER_ERROR',
    LOAD_ENVIRONMENT: 'LOAD_ENVIRONMENT',
    LOAD_ENVIRONMENT_RESP: 'LOAD_ENVIRONMENT_RESP',
    GET_VERSION: 'GET_VERSION',
    GET_VERSION_RESP: 'GET_VERSION_RESP',
    LOAD_SERVICE_ALERTS: 'LOAD_SERVICE_ALERTS',
    LOAD_SERVICE_ALERTS_RESP: 'LOAD_SERVICE_ALERTS_RESP',
    LOAD_LINKED_USER: 'LOAD_LINKED_USER',
    LOAD_LINKED_USER_RESP: 'LOAD_LINKED_USER_RESP',
    SAVE_TOKEN: 'SAVE_TOKEN',
    LOAD_PACKAGES: 'LOAD_PACKAGES',
    LOAD_PACKAGES_RESP: 'LOAD_PACKAGES_RESP',
    LOAD_PACKAGERS: 'LOAD_PACKAGERS',
    LOAD_PACKAGERS_RESP: 'LOAD_PACKAGERS_RESP',
    LOAD_GROUPS: 'LOAD_GROUPS',
    LOAD_GROUPS_RESP: 'LOAD_GROUPS_RESP',
    LOAD_ONE_PACKAGE: 'LOAD_ONE_PACKAGE',
    LOAD_ONE_PACKAGE_RESP: 'LOAD_ONE_PACKAGE_RESP',
}
